* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Noto IKEA", "Noto Sans", "Roboto", "Open Sans", system-ui,
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body * {
  font-family: "Noto IKEA", "Noto Sans", "Roboto", "Open Sans", system-ui,
    sans-serif !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.hookForm .MuiFormHelperText-root {
  color: rgb(224, 7, 81);
}

/* .choice-item--selected .choice-item__action + .choice-item__border, .choice-item--selected .choice-item__action:hover + .choice-item__border, .choice-item--selected .choice-item__action:active + .choice-item__border {
  border: none !important;
  border-color: white;
}

.choice-item__border {
  border: none !important;
}

.choice-item__action{
  border: none !important;
  padding: 0 !important;
  margin: 0 !important;
}

.choice__list-item{
  margin: 0;
}
.choice-item__nested {
  padding: 0 1.5rem 0.5rem 15px!important;
} */

.pill {
  margin-right: 10px;
}

.sidebarModalHeader {
  flex-direction: row !important;
}

.landingAuto
  .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd,
.landingAuto
  .MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiFilledInput-adornedEnd {
  background-color: white;
}

.occ-choice-wrapper .choice-item.choice-item--selected .choice-item__action {
  background-color: #0058a3;
  border-radius: 5px;
}

.occ-choice-wrapper .choice-item .choice-item__title {
  text-align: center;
}

.occ-choice-wrapper .choice-item.choice-item--selected .choice-item__title {
  color: #fff;
}

.occ-choice-wrapper .choice-item.choice-item--selected .choice-item__border {
  display: none;
}

.custom-toggle {
  height: 21px;
}

.custom-toggle .toggle__button--active,
.custom-toggle2 .toggle__button--active {
  color: #fff;
  border: none;
}

.custom-toggle .toggle__button--active {
  background-color: #0058a3;
}

.custom-toggle2 .toggle__button--active {
  background-color: #000;
}

.autocomplete__multi-value__label {
  max-width: 170px !important;
}

.errorContainer {
  margin-top: 50px;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.errorContainer h3 {
  margin-top: 0;
}

.clickableRow:hover {
  cursor: pointer;
  background-color: #0000000a;
}

.form-field-status__validation {
  color: #f44336;
}
.react-daterange-picker__calendar{
  z-index: 2!important;
}
