.container {
  display: flex;
  padding: 30px 60px;
}

.logo {
  padding-left: 75px;
}

.logoiO {
  height: 50px;
  padding-left: 50px;
}

.logoiO:hover {
  cursor: pointer;
}

.userIcon {
  margin-left: auto;
}

.user {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.btnPrimary {
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  font-size: 0.75rem;
  line-height: 1.3333333333;
  border-radius: 64px;
  transition-property: opacity, transform, padding;
  transition-duration: 250ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.4, 1);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 100px;
  background: #111111;
  height: 2.5rem;
  padding: 0 1.5rem;
  border: 0;;
}

.url {
  text-decoration: underline;
  color: #000;
}

.notificationBtnWrapper {
  position: relative;
}

.notificationBtnBadge {
  display: flex;
  justify-content: center;
  background-color: #0058a3;
  color: #ffffff;
  font-size: 0.8rem;
  width: 20px;
  height: 20px;
  position: absolute;
  top: -5px;
  right: -5px;
  border-radius: 50%;
}